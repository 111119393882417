<template>
  <div :class='pageClassByNavigationComponent'
    :style='minHeightStyle'>
    <component
      :is='pageNavigationComponent'
      :sidebar-links='sidebarConfigurations.sidebarLinks'
      :sidebar-page-title='sidebarConfigurations.sidebarPageTitle'
      :custom-background-stype='sidebarConfigurations.customBackgroundStyle' />
    <div class='flex-grow px-4 lg:px-0'>
      <welcome-conference-banner v-if='showConferenceBanner' class='mb-8 lg:mb-12' />
      <div class='py-8 lg:py-16 relative'>
        <h1 class='text-3xl font-bold mb-4 lg:mb-8'>{{ abstractPageTitle }}</h1>
        <div v-if='topHtmlContent' v-html='topHtmlContent'></div>
        <div class='mt-8'>
          <abstract-submission-affiliations v-if='includeAffiliations' class='mb-12' />
          <abstract-submission-authors v-if='includeAuthors' class='mb-12' />
          <abstract-submission-overview v-if='includeOverview' class='mb-12' />
          <abstract-submission-details v-if='includeDetails' class='mb-12' />
          <div v-if='includeKeywords' class='mb-12'>
            <label class='uppercase text-sm text-gray-900'>
              Keywords
              <span class='capitalize text-xs font-semibold text-red-500'>*</span>
              <span class='inline-block text-gray-500 text-xs normal-case'>(Please add one keyword at a time)</span>
            </label>
            <div>
              <el-tag
                :key='`keyword-${word}`'
                v-for='word in editingAbstractSubmission.keywords'
                closable
                :disable-transitions='false'
                @close='handleRemove(word)'>
                {{word}}
              </el-tag>
              <el-input
                class='input-new-keyword'
                v-if='keywordVisible'
                v-model='keywordValue'
                ref='saveKeywordInput'
                size='mini'
                @keyup.enter.native='handleKeywordConfirm'
                @blur='handleKeywordConfirm'>
              </el-input>
              <el-button v-else class='button-new-keyword' size='small' @click='showKeywordInput'>+ Keyword</el-button>
            </div>
          </div>
          <abstract-submission-extra-info v-if='includeExtraInfo' class='mb-12' />
        </div>
        <ul v-if='!showRequirementIndicatorOnTop'
          class='mt-8 list-disc list-inside text-red-600 text-sm'>
          <div v-for='formField in abstractSubmissionFormFields'
            :key='formField.keyName'>
            <li v-if='!fieldValue(formField.category, formField.keyName)'>
              {{ formField.name }} {{requirementIndicatorHelperText}}
            </li>
          </div>
        </ul>
        <ul class='list-disc list-inside text-red-600 text-sm'>
          <div v-for='requirement in additionalRequirementsIndicators'
            :key='requirement.keyName'>
            <li>
              {{ requirement.metaData.indicatorText }}
            </li>
          </div>
        </ul>
        <div class='w-full mt-8 sticky bottom-0 bg-gray-100 p-4'>
          <div class='flex flex-row justify-start items-center gap-x-8'>
            <button
              class='border py-3 px-16 text-center text-white text-sm uppercase  bg-gray-900 block rounded-md'
              :class='disabledPreviewAndSubmitButtonStyle'
              :disabled='disabledPreviewAndSubmitButton'
              @click='openPreview'>
              {{ abstractSubmitButtonText }}
            </button>
            <div class='uppercase text-sm'
              :class='needToReduceWordsStyle'>
              word limit: {{totalWordCount}} / {{abstractSubmissionWordLimit}}
            </div>
          </div>
        </div>
      </div>
      <welcome-sponsor-carousel  v-if='showSponsorCarousel'  class='max-w-4xl mt-16 lg:mt-20'/>
    </div>
    <modal
      name='edit-author'
      :adaptive='true'
      :height='840'
      :clickToClose='false'>
      <div class='p-8 h-full overflow-y-auto'>
        <button class='z-30 absolute top-3 right-3' @click="$modal.hide('edit-author')">
          <x-icon class='text-black' />
        </button>
        <h2 class='text-2xl font-semibold'>Author Details</h2>
        <h3 class='text-gray-500 text-base font-normal'>
          <span class='text-red-500 font-bold'>*</span>Please enter all information in English.
        </h3>
        <abstract-submission-edit-author class='my-8' />
      </div>
    </modal>
    <modal
      name='edit-affiliation'
      :adaptive='true'
      :height='480'
      :clickToClose='false'>
      <div class='p-8 h-full overflow-y-auto'>
        <button class='z-30 absolute top-3 right-3' @click="$modal.hide('edit-affiliation')">
          <x-icon class='text-black' />
        </button>
        <h2 class='text-2xl font-semibold'>Affiliation Details</h2>
        <h3 class='text-gray-500 text-base font-normal'>
          <span class='text-red-500 font-bold'>*</span>Please enter all information in English.
        </h3>
        <abstract-submission-edit-affiliation class='my-8' />
      </div>
    </modal>
    <modal
      name='preview-submission'
      :adaptive='true'
      :height='"85%"'
      :width='"90%"'>
      <div class='h-full relative'>
        <abstract-submission-preview
          :abstract='editingAbstractSubmission'
          class='overflow-y-auto pb-16' style='height: 100%;' />
        <div class='absolute bottom-0 right-0 bg-gray-100 p-4 flex flex-row justify-start items-center gap-x-8 w-full'>
          <button
            class='border py-3 px-16 text-center text-white text-sm uppercase bg-gray-900 block rounded-md'
            @click='submitAbstract'>
            {{ abstractSubmitButtonText }}
          </button>
        </div>
        <button
          class='p-2 absolute top-2 right-2 hover:bg-gray-200'
          @click='closePreview'>
          <x-icon class='h-5' />
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import { XIcon } from 'vue-tabler-icons'
import { mapGetters, mapActions, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import cloneDeep from 'lodash/cloneDeep'
import camelCase from 'lodash/camelCase'
import jsonLogic  from 'json-logic-js'

export default {
  name: 'AbstractSubmission',
  props: {
    showConferenceBanner: {
      type: Boolean,
      default: false,
    },
    showSponsorCarousel: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    'tabs-page-navigation': () => import('@/components/common/TabsPageNavigation'),
    'sidebar-page-navigation': () => import('@/components/common/SidebarPageNavigation'),
    'abstract-submission-authors': () => import('@/components/common/abstract-submission/AbstractSubmissionAuthors.vue'),
    'abstract-submission-affiliations': () => import('@/components/common/abstract-submission/AbstractSubmissionAffiliations.vue'),
    'abstract-submission-overview': () => import('@/components/common/abstract-submission/AbstractSubmissionOverview.vue'),
    'abstract-submission-details': () => import('@/components/common/abstract-submission/AbstractSubmissionDetails.vue'),
    'abstract-submission-extra-info': () => import('@/components/common/abstract-submission/AbstractSubmissionExtraInfo.vue'),
    'abstract-submission-edit-affiliation': () => import('@/components/common/abstract-submission/AbstractSubmissionEditAffiliation.vue'),
    'abstract-submission-edit-author': () => import('@/components/common/abstract-submission/AbstractSubmissionEditAuthor.vue'),
    'abstract-submission-preview': () => import('@/components/common/abstract-submission/AbstractSubmissionPreview.vue'),
    XIcon,
  },
  data () {
    return {
      keywordVisible: false,
      keywordValue: '',
    }
  },
  watch: {
    'abstractSubmissionDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.editingAbstractSubmission = cloneDeep(newVal)
        }
      },
      immediate: true,
      deep: true
    },
  },
  computed: {
    ...mapGetters('events',[
      'showingEventName',
      'eventMainThemeColor',
      'showingEventAbstractSubmissionsConfigurations',
    ]),
    ...mapFields('abstracts', [
      'editingAbstractSubmission'
    ]),
    ...mapState('abstracts', [
      'editingAuthor',
      'editingAffiliation',
    ]),
    ...mapState('users', [
      'hasValidToken',
      'profile',
    ]),
    pageClassByNavigationComponent () {
      return this.pageNavigationComponent === 'sidebar-page-navigation' ? 'flex flex-col lg:flex-row justify-between items-stretch gap-x-16' : ''
    },
    pageNavigationComponent () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.pageNavigationComponent) ? this.showingEventAbstractSubmissionsConfigurations.pageNavigationComponent : ''
    },
    showingAbstractId () {
      return parseInt(this.$route.query.abstract_submission_id)
    },
    abstractCreatePageTitle () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.abstractCreatePageTitle) ? this.showingEventAbstractSubmissionsConfigurations.abstractCreatePageTitle : 'Submit New Abstract'
    },
    abstractEditPageTitle () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.abstractEditPageTitle) ? this.showingEventAbstractSubmissionsConfigurations.abstractEditPageTitle : 'Edit Abstract'
    },
    abstractPageTitle () {
      return this.showingAbstractId ? this.abstractEditPageTitle : this.abstractCreatePageTitle
    },
    abstractCreateButtonText () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.abstractCreateButtonText) ? this.showingEventAbstractSubmissionsConfigurations.abstractCreateButtonText : 'Submit Abstract'
    },
    abstractEditButtonText () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.abstractEditButtonText) ? this.showingEventAbstractSubmissionsConfigurations.abstractEditButtonText : 'Edit Abstract'
    },
    abstractCreateConfirmButtonText () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.abstractCreateConfirmButtonText) ? this.showingEventAbstractSubmissionsConfigurations.abstractCreateConfirmButtonText : 'Submit Abstract'
    },
    abstractEditConfirmButtonText () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.abstractEditConfirmButtonText) ? this.showingEventAbstractSubmissionsConfigurations.abstractEditConfirmButtonText : 'Edit Abstract'
    },
    abstractSubmitConfirmButtonText () {
      return this.showingAbstractId ? this.abstractEditConfirmButtonText : this.abstractCreateConfirmButtonText 
    },
    abstractSubmitButtonText () {
      return this.showingAbstractId ? this.abstractEditButtonText : this.abstractCreateButtonText
    },
    abstractCreateConfirmMessage () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.abstractCreateConfirmMessage) ? this.showingEventAbstractSubmissionsConfigurations.abstractCreateConfirmMessage : 'Submit this abstract?'
    },
    abstractEditConfirmMessage () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.abstractEditConfirmMessage) ? this.showingEventAbstractSubmissionsConfigurations.abstractEditConfirmMessage : 'Edit this abstract?'
    },
    abstractSubmitConfirmMessage () {
      return this.showingAbstractId ? this.abstractEditConfirmMessage : this.abstractCreateConfirmMessage
    },
    abstractCreateDoneMessage () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.abstractCreateDoneMessage) ? this.showingEventAbstractSubmissionsConfigurations.abstractCreateDoneMessage : 'Abstract submission done'
    },
    abstractEditDoneMessage () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.abstractEditDoneMessage) ? this.showingEventAbstractSubmissionsConfigurations.abstractEditDoneMessage : 'Abstract edit done'
    },
    abstractSubmitDoneMessage () {
      return this.showingAbstractId ? this.abstractEditDoneMessage : this.abstractCreateDoneMessage
    },
    abstractSubmitCancelButtonText () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.abstractSubmitCancelButtonText) ? this.showingEventAbstractSubmissionsConfigurations.abstractSubmitCancelButtonText : 'Cancel'
    },
    minHeightStyle () {
      let minHeight = 64 // size of the navbar
      return `min-height: calc(100vh - ${minHeight}px);`
    },
    includeDisclosures () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.includeDisclosures) ? this.showingEventAbstractSubmissionsConfigurations.includeDisclosures : false
    },
    includeAffiliations () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.includeAffiliations) ? this.showingEventAbstractSubmissionsConfigurations.includeAffiliations : false
    },
    includeAuthors () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.includeAuthors) ? this.showingEventAbstractSubmissionsConfigurations.includeAuthors : false
    },
    includeOverview () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.includeOverview) ? this.showingEventAbstractSubmissionsConfigurations.includeOverview : false
    },
    includeDetails () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.includeDetails) ? this.showingEventAbstractSubmissionsConfigurations.includeDetails : false
    },
    includeExtraInfo () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.includeExtraInfo) ? this.showingEventAbstractSubmissionsConfigurations.includeExtraInfo : false
    },
    includeKeywords () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.includeKeywords) ? this.showingEventAbstractSubmissionsConfigurations.includeKeywords : false
    },
    sidebarConfigurations () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.sidebarConfigurations) ? this.showingEventAbstractSubmissionsConfigurations.sidebarConfigurations : {}
    },
    topHtmlContent () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.topHtmlContent) ? this.showingEventAbstractSubmissionsConfigurations.topHtmlContent : ''
    },
    abstractSubmissionDefault () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.abstractSubmissionDefault) ? this.showingEventAbstractSubmissionsConfigurations.abstractSubmissionDefault : null
    },
    abstractSubmissionOverviewConfigs () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.abstractSubmissionOverviewConfigs) ? this.showingEventAbstractSubmissionsConfigurations.abstractSubmissionOverviewConfigs : null
    },
    abstractSubmissionOverviewFormFields () {
      return (this.abstractSubmissionOverviewConfigs && this.abstractSubmissionOverviewConfigs.formFields) ?
        this.abstractSubmissionOverviewConfigs.formFields.concat()
        .filter((field) => {
          let condition = field.metaData.showCondition ? field.metaData.showCondition : true
          return jsonLogic.apply(condition, {abstractSubmission: this.editingAbstractSubmission, currentDateTime: this.currentDateTimeString})
        })
        .sort((fieldA, fieldB) => fieldA.sortOrder - fieldB.sortOrder) : []
    },
    abstractSubmissionDetailsConfigs () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.abstractSubmissionDetailsConfigs) ? this.showingEventAbstractSubmissionsConfigurations.abstractSubmissionDetailsConfigs : null
    },
    abstractSubmissionDetailsFormFields () {
      return (this.abstractSubmissionDetailsConfigs && this.abstractSubmissionDetailsConfigs.formFields) ?
        this.abstractSubmissionDetailsConfigs.formFields.concat()
        .filter((field) => {
          let condition = field.metaData.showCondition ? field.metaData.showCondition : true
          return jsonLogic.apply(condition, {abstractSubmission: this.editingAbstractSubmission, currentDateTime: this.currentDateTimeString})
        })
        .sort((fieldA, fieldB) => fieldA.sortOrder - fieldB.sortOrder) : []
    },
    showRequirementIndicatorOnTop () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.showRequirementIndicatorOnTop) ? this.showingEventAbstractSubmissionsConfigurations.showRequirementIndicatorOnTop : false
    },
    requirementIndicatorHelperText () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.requirementIndicatorHelperText) ? this.showingEventAbstractSubmissionsConfigurations.requirementIndicatorHelperText : 'is required.'
    },
    abstractSubmissionWordLimit () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.abstractSubmissionWordLimit) ? this.showingEventAbstractSubmissionsConfigurations.abstractSubmissionWordLimit : 450
    },
    abstractSubmissionFormFields () {
      return this.abstractSubmissionOverviewFormFields.concat(this.abstractSubmissionDetailsFormFields)
    },
    disabledPreviewAndSubmitButton () {
      return this.abstractSubmissionFormFields.map(field => this.fieldValue(field.category, field.keyName)).some(value => !value) || Object.values(this.additionalRequirements).some(requirement => requirement)
    },
    disabledPreviewAndSubmitButtonStyle () {
      return (this.disabledPreviewAndSubmitButton) ? 'opacity-25 cursor-not-allowed' : ''
    },
    needToAnswerDisclosures () {
      return this.includeDisclosures && !this.editingAbstractSubmission.disclosures.every(disclosure => {
        if (disclosure.answerType === 'multi-select') {
          return disclosure.answers.length > 0
        } else if (disclosure.requiredDetails && disclosure.answer !== 'None') {
          return disclosure.answer && disclosure.collectDetails.every(detail => detail.answer)
        } else {
          return disclosure.answer
        }
      })
    },
    needToAddAffiliations () {
      return this.includeAffiliations && !this.editingAbstractSubmission.affiliations.length
    },
    needToAddAuthors () {
      return this.includeAuthors && !this.editingAbstractSubmission.authors.length
    },
    needToAddKeywords () {
      return this.includeKeywords && !this.editingAbstractSubmission.keywords.length
    },
    needToReduceWords () {
      return this.totalWordCount > this.abstractSubmissionWordLimit
    },
    needToCheckNumOfPresenter () {
      return this.editingAbstractSubmission.authors.filter(author => author.isPresenter).length !== 1
    },
    needToCheckNumOfCorrespondingAuthor () {
      return this.editingAbstractSubmission.authors.filter(author => author.isCorrespondant).length !== 1
    },
    needToReduceWordsStyle () {
      return this.needToReduceWords ? 'text-red-500 font-semibold' : ''
    },
    additionalRequirements () {
      return {
        needToAnswerDisclosures: this.needToAnswerDisclosures,
        needToAddAuthors: this.needToAddAuthors,
        needToAddAffiliations: this.needToAddAffiliations,
        needToReduceWords: this.needToReduceWords,
        needToAddKeywords: this.needToAddKeywords,
        needToCheckNumOfPresenter: this.needToCheckNumOfPresenter,
        needToCheckNumOfCorrespondingAuthor: this.needToCheckNumOfCorrespondingAuthor
      }
    },
    additionalRequirementsIndicators () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.additionalRequirementsIndicators) ?
        this.showingEventAbstractSubmissionsConfigurations.additionalRequirementsIndicators.concat()
        .filter((requirement) => {
          let condition = requirement.metaData.showCondition ? requirement.metaData.showCondition : false
          return jsonLogic.apply(condition, {additionalRequirements: this.additionalRequirements})
        }) : []
    },
    totalWordCount () {
      return this.abstractSubmissionDetailsFormFields.map(field => this.fieldValue(field.category, field.keyName)).reduce((acc, curr) => {
          return acc + parseInt(this.wordCount(curr))
        }, 0)
    },
  },
  methods: {
    ...mapActions('users', [
      'checkTokenStatus',
      'getProfile',
    ]),
    ...mapActions([
      'hideFullpageLoadingIndicator',
      'showFullpageLoadingIndicator',
    ]),
    ...mapActions('abstracts', [
      'saveMyAbstract',
      'getEditingAbstract',
    ]),
    updateEditFormFieldValue (category, keyName, value) {
      let arr = keyName.split('.').map(keyName => camelCase(keyName))
      if (category === 'abstract_submission') {
        if (arr.length === 2) {
          this.editingAbstractSubmission[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          this.editingAbstractSubmission[arr[0]] = value
        }
      }
    },                                 
    fieldValue (category, keyName) {
      let arr = keyName.split('.').map(keyName => camelCase(keyName))
      let val = ''
      if (category === 'abstract_submission') {
        if (arr.length === 2) {
          val = this.editingAbstractSubmission[arr[0]][arr[1]]
        } else if (arr.length === 1) {
          val = this.editingAbstractSubmission[arr[0]]
        }
      }
      return val
    },
    openPreview () {
      this.$modal.show('preview-submission')
    },
    closePreview () {
      this.$modal.hide('preview-submission')
    },
    wordCount (string) {
      let words = cloneDeep(string)
      words = words.replace(/(^\s*)|(\s*$)/gi, '') // exclude start and end white-space
      words = words.replace(/[ ]{2,}/gi, ' ') // 2 or more space to 1
      words = words.replace(/\n /, '\n') // exclude newline with a start spacing
      return words.split(' ').filter((str) => str !== '').length
    },
    submitAbstract () {
      this.$confirm(this.abstractSubmitConfirmMessage, this.showingEventName, {
        type: 'success',
        confirmButtonText: this.abstractSubmitConfirmButtonText,
        cancelButtonText: this.abstractSubmitCancelButtonText
      }).then(() => {
        this.showFullpageLoadingIndicator()
        this.saveMyAbstract().then(() => {
          this.hideFullpageLoadingIndicator()
          this.$alert(this.abstractSubmitDoneMessage, this.showingEventName, {
            type: 'success',
            confirmButtonText: 'OK',
            showClose: false,
          }).then(() => {
            this.$router.push({name: 'AbstractSubmissions'})
          })
        })
      })
    },
    handleRemove (word) {
      this.editingAbstractSubmission.keywords.splice(this.editingAbstractSubmission.keywords.indexOf(word), 1)
    },
    showKeywordInput () {
      this.keywordVisible = true
      this.$nextTick(() => {
        this.$refs.saveKeywordInput.$refs.input.focus()
      })
    },
    handleKeywordConfirm () {
      let keywordValue = this.keywordValue
      if (keywordValue) {
        this.editingAbstractSubmission.keywords.push(keywordValue)
      }
      this.keywordVisible = false
      this.keywordValue = ''
    }
  },
  created () {
    this.checkTokenStatus().then(() => {
      if (!this.hasValidToken) {
        this.$router.replace({name: 'Login', query: {redirect_route: 'AbstractSubmissions'} })
      } else {
        let abstractId = parseInt(this.$route.query.abstract_submission_id)
        if (abstractId === 0) {
          this.editingAbstractSubmission = cloneDeep(this.abstractSubmissionDefault)
          this.getProfile().then(() => {
            // set default author as user
            if (this.includeAuthors) {
              this.editingAbstractSubmission.authors[0].email = this.profile.email
              this.editingAbstractSubmission.authors[0].firstName = this.profile.firstName
              this.editingAbstractSubmission.authors[0].lastName = this.profile.lastName
              this.editingAbstractSubmission.authors[0].affiliations = [{
                affiliation: this.profile.organization,
                department: this.profile.department,
                country: this.profile.country
              }]
              this.editingAbstractSubmission.authors[0].isPresenter = true
              this.editingAbstractSubmission.authors[0].isCorrespondant = true
              this.editingAbstractSubmission.authors[0].phoneNumber = this.profile.phoneNumber
            }
            if (this.includeAffiliations) {
              this.editingAbstractSubmission.affiliations[0].affiliation = this.profile.organization
              this.editingAbstractSubmission.affiliations[0].department = this.profile.department
              this.editingAbstractSubmission.affiliations[0].country = this.profile.country
            }
          })
        } else {
          this.getEditingAbstract(abstractId)
        }
      }
    })
  },
}
</script>
